@import "light-variables.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/leaflet/dist/leaflet.css";
@import "src/scripts/leaflet-ruler/leaflet-ruler.css";
@import "./node_modules/leaflet-easybutton/src/easy-button.css";
@import "node_modules/select2/src/scss/core.scss";
@import "./node_modules/@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";
@import "light-bootswatch.scss";
@import "./animate";
@import "./node_modules/datatables.net-bs4/css/dataTables.bootstrap4";
@import "./node_modules/datatables.net-responsive-bs4/css/responsive.bootstrap4";
@import "./node_modules/bootstrap4-toggle/css/bootstrap4-toggle.min.css";

@font-face {
  font-family: UnileverShilling;
  src: url('/webfonts/UnileverShilling.woff') format('woff');
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: UnileverShilling, Arial, sans-serif !important;
}

a {
  color: $primary;
}

.td-text-middle td {
  vertical-align: middle;
}

.main-wrapper {
  padding: 15px;
}

.brand-logo {
  width: 185px;
}

.device-logo{
  width: 100px;
}

.page-title h2 {
  margin:0;
  padding: 10px 10px 0;
}

.page-menu {
  float: right;
  margin-top: 10px;;
}

.parent .child-hide {
  visibility: hidden;
}

.parent:hover .child-hide {
  visibility: visible;
}

.auxside {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 450px; // caution! changed in src/scripts/auxside.js
  max-width: 90%;
  overflow: auto;
  border-left: 1px solid #002b36f2;
  z-index: 3;
}

.auxside-title {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #002b36f2;
  font-size: 1.2rem;
}

.icon-shape {
  position: absolute;
  top: 8px;
  right: 5px;
  width: 32px;
  padding-top: 3px;
  height: 32px;
  justify-content: center;
  text-align: center;
}

.full-wrap-black {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: .8;
  z-index: 500;
}

  .full-wrap-black span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    padding: 15px;
    border: 1px solid $white;
  }

.leaflet-default-icon-path {
  background-image: url(/images/marker-icon.png);
}

.easy-button-button.active {
  background-color: $primary;
  color: #ffffff;
}

.leaflet-control-layers-toggle {
  background-image: url(/images/layers.png);
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(/images/layers-2x.png);
}

.leaflet-default-icon-path {
  background-image: url(/images/marker-icon.png);
}

.btn-widget-settings {
  float: left;
  padding: 2px;
}

#dark-tile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 1;
  opacity: 0.5;
}

/* Fix SELECT2 para Bootswatch solarized*/
span.select2-container--bootstrap4 {
  background-color: $input-bg;
  border-radius: .25rem;
}
span.select2-selection--multiple {
  border-radius: .25rem;
}

input.select2-search__field {
  background: transparent;
}

.select2-results__option[aria-selected=true] {
  display: none;
}
/* END Fix SELECT2 */

.btn-xs {
  padding: .15rem .25rem;
  font-size: .70rem;
  line-height: 1.2;
  border-radius: .2rem;
}

@media only screen and (max-width: 768px) {
  #main {
    top: 60px;
  }

  .navbar-toggler-icon i {
    font-size: 20px;
    color: #30397c;
  }

  .parent .child-hide {visibility: visible;}

  .navbar-toggler-icon {
    width: auto;
    height: auto;
  }
}

.btn-header a.btn-link {
  padding: 0;
}

h5.card-title {margin: 0}

.dropdown-item .fas {
  visibility: visible;
}

.dropdown-item.active {
  background: $gray-900;
}

.dropdown-item.active .fas {
  visibility: visible;
}

.card-columns .card-header {
  padding: 5px 10px;
}

.widget-big-number {
  font-size: 40px;
  color: #3FB618 ;
}

@media (min-width: 34em) {
    .card-columns {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (min-width: 48em) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

@media (min-width: 62em) {
    .card-columns {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
    }
}

@media (min-width: 75em) {
    .card-columns {
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5;
    }
}

.group-border{
  border-radius: 5px;
  border-color: $primary;
}

.position{
  position: absolute;
  right: 50px;
}

.badge-pill{
  border-radius: 3px;
}

.modal-content{
  border-radius: 5px;
}

.btn-devices {
      cursor: pointer;
    }

