// Cosmo 4.3.1
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #73706e !default;
$gray-700: #495057 !default;
$gray-800: #1d2022 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #041f47 !default;
$indigo:  #6610f2 !default;
$purple:  #7705BC !default;
$pink:    #e83e8c !default;
$red:     #FF0039 !default;
$orange:  #f0ad4e !default;
$yellow:  #FF7518 !default;
$green:   #3FB618 !default;
$teal:    #20c997 !default;
$cyan:    #9954BB !default;

$primary:       $blue !default;
$secondary:     $purple !default;
$success:       $green !default;
$info:          $teal !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-300 !default;
$dark:          $gray-800 !default;

// Options

$enable-rounded: false !default;

// Body

$body-color:    $gray-600 !default;

// Fonts

$font-family-sans-serif: "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial, sans-serif !default;

$font-size-base: 0.9375rem !default;

$headings-font-weight:   300 !default;

// Navbar

$navbar-dark-hover-color:           rgba($white,1) !default;

$navbar-light-hover-color:          rgba($black,.9) !default;

// Alerts

$alert-border-width:                0 !default;

// Progress bars

$progress-height:               0.5rem !default;
